import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import { sendCoinsUrl, sendDiamondsUrl, sendHeartsUrl, sendNotifUrl, sendSubUrl, uploadNotifcationImagesUrl } from '../../constants';


export const SendNotifSlice = createSlice({
    name: 'sendNotif',
    initialState: {

        fetchSendNotifResult: null,
        fetchSendNotifError: null,
        isFetchingSendNotif: false,

        fetchUploadNotificationImageResult: null,
        fetchUploadNotificationImageError: null,
        isUploadingNotificationImage: false,
        notifImgUrl: null,

        isGivingAway: false,

        diamondsGiveawayResult: null,
        diamondsGiveawayError: null,

        coinsGiveawayResult: null,
        coinsGiveawayError: null,

        heartsGiveawayResult: null,
        heartsGiveawayError: null,

        subGiveawayResult: null,
        subGiveawayError: null,

    },
    reducers: {
        setFetchSendNotifResult: (state, action) => {
            state.fetchSendNotifResult = action.payload;
            state.isFetchingSendNotif = false;
        },

        setFetchSendNotifError: (state, action) => {
            state.fetchSendNotifError = action.payload;
            state.isFetchingSendNotif = false;
            state.fetchSendNotifResult = null

        },

        setIsFetchingSendNotif: (state, action) => {
            state.isFetchingSendNotif = action.payload;
        },

        setNotificationImageUrl: (state, action) => {
            state.notifImgUrl = action.payload;
        },
        setUploadingNotificationImage: (state, action) => {
            if (action.payload) {
                state.fetchUploadNotificationImageResult = null;
                state.fetchUploadNotificationImageError = null;
            }
            state.isUploadingNotificationImage = action.payload;
        },
        setUploadNotificationImageResult: (state, action) => {
            state.fetchUploadNotificationImageResult = action.payload;
            state.isUploadingNotificationImage = false;

        },
        setUploadNotificationImageError: (state, action) => {
            state.fetchUploadNotificationImageError = action.payload;
            state.isUploadingNotificationImage = false;
        },

        setIsGivingAway: (state, action) => {
            state.isGivingAway = action.payload;
        },

        setDiamondGiveawayResult: (state, action) => {
            state.diamondsGiveawayResult = action.payload;
            state.diamondsGiveawayError = null;
            state.isGivingAway = false;
        },
        setDiamondGiveawayError: (state, action) => {
            state.diamondsGiveawayError = action.payload;
            state.diamondsGiveawayResult = null;
            state.isGivingAway = false;
        },
        
        setCoinsGiveawayResult: (state, action) => {
            state.coinsGiveawayResult = action.payload;
            state.coinsGiveawayError = null;
            state.isGivingAway = false;
        },
        setCoinsGiveawayError: (state, action) => {
            state.coinsGiveawayError = action.payload;
            state.coinsGiveawayResult = null;
            state.isGivingAway = false;
        },
        
        setHeartsGiveawayResult: (state, action) => {
            state.heartsGiveawayResult = action.payload;
            state.heartsGiveawayError = null;
            state.isGivingAway = false;
        },
        setHeartsGiveawayError: (state, action) => {
            state.heartsGiveawayError = action.payload;
            state.heartsGiveawayResult = null;
            state.isGivingAway = false;
        },
        
        setSubGiveawayResult: (state, action) => {
            state.subGiveawayResult = action.payload;
            state.subGiveawayError = null;
            state.isGivingAway = false;
        },
        setSubGiveawayError: (state, action) => {
            state.subGiveawayError = action.payload;
            state.subGiveawayResult = null;
            state.isGivingAway = false;
        },
    }
});

export const {


    setFetchSendNotifResult,
    setFetchSendNotifError,
    setConnected,
    setIsFetchingSendNotif,

    setUploadingNotificationImage,
    setUploadNotificationImageResult,
    setUploadNotificationImageError,
    setNotificationImageUrl,

    setIsGivingAway,

    setDiamondGiveawayResult,
    setDiamondGiveawayError,

    setCoinsGiveawayResult,
    setCoinsGiveawayError,

    setHeartsGiveawayResult,
    setHeartsGiveawayError,
    
    setSubGiveawayResult,
    setSubGiveawayError,


} = SendNotifSlice.actions;

export const sendNotification = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: sendNotifUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsFetchingSendNotif(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchSendNotifResult(response.data));
            message.success(response.data.message)
        })
        .catch(err => {
            if (err.response) {
                message.error(err.response.data.message)

                dispatch(setFetchSendNotifError(err.response.data.error));
            }
            else {
            }
        });

    return response.data;

}

export const fetchUploadNotificationImage = (data, file) => (dispatch, getState) => {
    dispatch(setUploadingNotificationImage(true));
    let formData = new FormData();
    formData.append("image", file);

    let url = uploadNotifcationImagesUrl;
    const response = axios
        .post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.token,
            },
        })
        .then((response) => {
            dispatch(setUploadNotificationImageResult(response.data));
            dispatch(setNotificationImageUrl(response.data.img_url));
            // console.log("upload image response ", response.data);
        })
        .catch((response) => {
            dispatch(setUploadNotificationImageError(response.response.data.message));
            message.error(response.response.data.message);
        });
    // console.log("blablabla", response);
    return response.data;
};

export const sendDiamondsGiveaway = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: sendDiamondsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsGivingAway(true));
    const response = axios(config)
        .then(response => {
            dispatch(setDiamondGiveawayResult(response.data));
            message.success(response.data.message)
        })
        .catch(err => {
            if (err.response) {
                message.error(err.response.data.message)

                dispatch(setDiamondGiveawayError(err.response.data.error));
            }
            else {
            }
        });

    return response.data;
}
export const sendCoinsGiveaway = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: sendCoinsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsGivingAway(true));
    const response = axios(config)
        .then(response => {
            dispatch(setCoinsGiveawayResult(response.data));
            message.success(response.data.message)
        })
        .catch(err => {
            if (err.response) {
                message.error(err.response.data.message)

                dispatch(setCoinsGiveawayError(err.response.data.error));
            }
            else {
            }
        });

    return response.data;
}
export const sendHeartsGiveaway = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: sendHeartsUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsGivingAway(true));
    const response = axios(config)
        .then(response => {
            dispatch(setHeartsGiveawayResult(response.data));
            message.success(response.data.message)
        })
        .catch(err => {
            if (err.response) {
                message.error(err.response.data.message)

                dispatch(setHeartsGiveawayError(err.response.data.error));
            }
            else {
            }
        });

    return response.data;
}
export const sendSubGiveaway = (data) => (dispatch, getState) => {
    const config = {
        method: "post",
        url: sendSubUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsGivingAway(true));
    const response = axios(config)
        .then(response => {
            dispatch(setSubGiveawayResult(response.data));
            message.success(response.data.message)
        })
        .catch(err => {
            if (err.response) {
                message.error(err.response.data.message)

                dispatch(setSubGiveawayError(err.response.data.error));
            }
            else {
            }
        });

    return response.data;
}


export default SendNotifSlice.reducer;