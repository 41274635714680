import { Content } from "antd/es/layout/layout";
import PageHeader from "../../comps/pageHeader";
import { Button, Card, Col, Form, Input, List, Modal, Row, Space, Spin, Switch, Typography } from "antd";
import { useEffect, useState } from "react";
import TotalLecons from "../lessons/numLessonsComps";
import { useDispatch, useSelector } from "react-redux";
import { CreateModule, editlModule, fetchModules, setCreateModuleResult, ToggleModule } from "./moduleSlice";

const { Text } = Typography;

export default function ModuleList({ props }) {

    const dispatch = useDispatch();

    const moduleList = useSelector((state) => state.module.fetchModulesResult);
    const toggleModuleResult = useSelector((state) => state.module.toggleModuleResult);
    const editModuleResult = useSelector((state) => state.module.editModuleResult);
    const isCreatingModule = useSelector((state) => state.module.isCreatingModule);
    const createModuleResult = useSelector((state) => state.module.createModuleResult);

    const [isAddModuleModalOpen, setIsAddModuleModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        trial: false,
    });


    useEffect(() => {
        dispatch(fetchModules());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleModuleResult, editModuleResult]);

    useEffect(() => {
        if (!createModuleResult) return;

        dispatch(fetchModules());
        dispatch(setCreateModuleResult(null));
        setIsAddModuleModalOpen(false);
        setFormData({
            name: "",
            trial: false,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createModuleResult]);

    const handleFormSubmit = () => {
        if (isCreatingModule) return;
        dispatch(CreateModule(formData));
    }

    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <PageHeader
                title={"Modules"}
                breadcrumbItems={["Modules", "Vue d'ensemble"]}
            />

            <Row justify={"center"} style={{ background: "#424242", margin: "24px", minHeight: "112px" }}
            >
                <Col span={5} style={{ paddingTop: 10 }}>
                    <Space align='center' direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Text style={{ color: "white", fontSize: "16px" }}>Ajouter un nouveau module</Text>
                        <Button type='primary'
                            onClick={() => {
                                setIsAddModuleModalOpen(true);
                                setFormData({
                                    name: "",
                                    trial: false,
                                })
                            }}
                            style={{ width: "132px", height: "32px" }}>Ajouter</Button>
                    </Space>
                </Col>
            </Row>
            <Row justify={"start"} style={{ margin: "24px" }}>
                <List
                    grid={true}
                    style={{ gap: "24px" }}
                    dataSource={moduleList?.modules ? moduleList.modules : []}
                    renderItem={
                        (item, index) => {
                            return (
                                <Card title={
                                    (<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                        <h2 style={{ marginRight: "24px", fontSize: "21px" }}>{`Module ${index + 1} - ${item.name}`}</h2>
                                        <Switch checked={item.status}
                                            onChange={(checked) => {
                                                dispatch(ToggleModule({ id: item._id, status: checked }));
                                            }}
                                        />
                                    </div>)
                                }
                                    bordered={false}
                                    style={{ minHeight: '350px', minWidth: "300px", width: "100%", marginRight: "24px" }}
                                >
                                    <Form layout="horizontal"  >

                                        <Form.Item
                                            label={<p style={{ fontSize: "18px" }}>Gratuit ou en essai</p>}
                                            labelAlign="left"
                                            labelCol={{ span: 21 }}
                                            style={{ marginBottom: '18px' }}
                                        >
                                            <Switch checked={item.trial}
                                                onChange={(checked) => {
                                                    dispatch(editlModule({ id: item._id, trial: checked, name: item.name }));
                                                }}
                                            />
                                        </Form.Item>
                                    </Form>
                                    <TotalLecons total={item.totalLessons} numSeq={item.seq.toString()} />
                                </Card>
                            )
                        }
                    }
                >

                </List>
            </Row>

            <Modal
                open={isAddModuleModalOpen}
                okText={isCreatingModule ? <Spin className='proClientSpinner' /> : "Ajouter"}
                centered
                maskClosable={false}
                closable={false}
                onOk={handleFormSubmit}
                okButtonProps={{ disabled: (formData.name === "") }}
                onCancel={() => {
                    setIsAddModuleModalOpen(false);
                    setFormData({
                        name: "",
                        trial: false,
                    })
                }
                }
                width={600}
                maskStyle={{
                    backgroundColor: "#10101088",
                    backdropFilter: "blur(0.7rem)",
                }}
            >
                <Form>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Nom du module"
                        rules={[
                            { required: true, message: "Veuillez saisir un nom" },
                        ]}
                    >
                        <Input
                            name="name"
                            type="name"
                            placeholder="Nom"
                            value={formData.name}
                            defaultValue={""}
                            onChange={(e) => { setFormData({ ...formData, name: e.target.value }) }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="trial"
                        label="Gratuit ou en essai"
                    >
                        <Switch
                            style={{ float: "right" }}
                            checked={formData.trial}
                            onChange={(checked) => { setFormData({ ...formData, trial: checked }) }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </Content>
    )
};