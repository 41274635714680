import { createSlice } from "@reduxjs/toolkit";

import { getModuleUrl, editModuleUrl, getModulesUrl, toggleModuleUrl, createModuleUrl } from "../../constants";
import axios from "axios";
import { message } from "antd";
export const ModuleSlice = createSlice({
    name: "module",
    initialState: {
        fetchModuleResult: null,
        fetchModuleError: null,
        isfetchingModule: false,

        fetchModulesResult: null,
        fetchModulesError: null,
        isFetchingModules: false,

        toggleModuleResult: null,
        toggleModuleError: null,
        isTogglingModule: false,

        editModuleResult: null,
        editModuleError: null,
        isEditingModule: false,

        createModuleResult: null,
        createModuleError: null,
        isCreatingModule: false,
    },
    reducers: {
        setModuleResult: (state, action) => {
            state.fetchModuleResult = action.payload;
            state.isfetchingModule = false;
        },
        setModuleError: (state, action) => {
            state.fetchModuleError = action.payload;
            state.isfetchingModule = false;
        },
        setisfetchingModule: (state, action) => {
            state.isfetchingModule = action.payload;
        },

        setFetchModulesResult: (state, action) => {
            state.fetchModulesResult = action.payload;
            state.isFetchingModules = false;
        },

        setFetchModulesError: (state, action) => {
            state.fetchModulesError = action.payload;
            state.isFetchingModules = false;
            state.fetchModulesResult = null

        },
        setIsFetchingModules: (state, action) => {
            state.isFetchingModules = action.payload;
        },
        
        setToggleModuleResult: (state, action) => {
            state.toggleModuleResult = action.payload;
            state.toggleModuleError = null;
            state.isTogglingModule = false;
        },

        setToggleModuleError: (state, action) => {
            state.toggleModuleError = action.payload;
            state.toggleModuleResult = null
            state.isTogglingModule = false;

        },
        setIsTogglingModule: (state, action) => {
            state.isTogglingModule = action.payload;
        },
        
        setEditModuleResult: (state, action) => {
            state.editModuleResult = action.payload;
            state.editModuleError = null;
            state.isEditingModule = false;
        },

        setEditModuleError: (state, action) => {
            state.editModuleError = action.payload;
            state.editModuleResult = null
            state.isEditingModule = false;

        },
        setIsEditingModule: (state, action) => {
            state.isEditingModule = action.payload;
        },
        
        setCreateModuleResult: (state, action) => {
            state.createModuleResult = action.payload;
            state.createModuleError = null;
            state.isCreatingModule = false;
        },

        setCreateModuleError: (state, action) => {
            state.createModuleError = action.payload;
            state.createModuleResult = null
            state.isCreatingModule = false;

        },
        setIsCreatingModule: (state, action) => {
            state.isCreatingModule = action.payload;
        },
    },
});

export const {
    setModuleResult,
    setModuleError,
    setisfetchingModule,

    setFetchModulesResult,
    setFetchModulesError,
    setIsFetchingModules,

    setToggleModuleResult,
    setToggleModuleError,
    setIsTogglingModule,

    setEditModuleResult,
    setEditModuleError,
    setIsEditingModule,

    setCreateModuleResult,
    setCreateModuleError,
    setIsCreatingModule,

} = ModuleSlice.actions;

export const fetchSinglModule = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getModuleUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data,
    };
    dispatch(setisfetchingModule(true));

    const response = axios(config)
        .then((response) => {
            dispatch(setModuleResult(response.data));
        })
        .catch((response) => {
            dispatch(setModuleError(response.message));
        });

    console.log(response);
    return response.data;
};


export const editlModule = (data) => async (dispatch, getState) => {
    dispatch(setIsEditingModule(true));
    
    const config = {
        method: "post",
        url: editModuleUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };

    const response = await axios(config)
        .then((response) => {
            message.success(response.data.message)
            dispatch(setEditModuleResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setEditModuleError(response.data))

        });
    return response;
};

export const ToggleModule = (data) => async (dispatch, getState) => {
    dispatch(setIsTogglingModule(true));
    
    const config = {
        method: "post",
        url: toggleModuleUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };

    const response = await axios(config)
        .then((response) => {
            message.success(response.data.message)
            dispatch(setToggleModuleResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setToggleModuleError(response.data));

        });
    return response;
};

export const CreateModule = (data) => async (dispatch, getState) => {
    dispatch(setIsCreatingModule(true));
    
    const config = {
        method: "post",
        url: createModuleUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data
    };

    const response = await axios(config)
        .then((response) => {
            message.success(response.data.message)
            dispatch(setCreateModuleResult(response.data));
        })
        .catch((error) => {
            message.error(error.response.data.message)
            dispatch(setCreateModuleError(response.data));

        });
    return response;
};

export const fetchModules = (data) => (dispatch, getState) => {

    const config = {
        method: "post",
        url: getModulesUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.token,
        },
        data

    };
    dispatch(setIsFetchingModules(true));
    const response = axios(config)
        .then(response => {
            dispatch(setFetchModulesResult(response.data));
        })
        .catch(err => {
            if (err.respons) {
                dispatch(setFetchModulesError(err.response.data.error));
            }

        });

    return response.data;

}

export default ModuleSlice.reducer;