import { Progress, Space, Typography } from 'antd';
import { useParams } from 'react-router-dom';


const { Title } = Typography;



const TotalLecons = ({ total, numSeq }) => {
    const { seq } = useParams();

    const getSeq = () => {
        if (!numSeq) {
            return seq;
        }
        return numSeq;
    }

    return (
        <Space
            direction="vertical"
            size="middle"
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Title level={5} > Capacité (Leçons)</Title>

            <Progress type="circle" percent={getSeq() === "1" ? (total * 100 / 10) : (total * 100 / 42)}
                format={(percent) => `${total}${getSeq() === "1" ? "/10" : "/42"}`}
                strokeColor='#39B13D'
            />
        </Space>

    );
}

export default TotalLecons;