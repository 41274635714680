import { Content } from "antd/es/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../comps/pageHeader";
import { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, InputNumber, message, Row, Select, Space, Spin } from "antd";
import { fetchUsers } from "../users/usersListSlice";
import { sendCoinsGiveaway, sendDiamondsGiveaway, sendHeartsGiveaway, sendSubGiveaway, setCoinsGiveawayResult, setDiamondGiveawayResult, setHeartsGiveawayResult, setSubGiveawayResult } from "./notificationSlice";

export default function Giveaway() {

    const dispatch = useDispatch();

    const usersResult = useSelector((state) => state.users.fetchUsersResult);
    const isGivingaway = useSelector((state) => state.sendNotif.isGivingAway);
    const diamondsGiveawayResult = useSelector((state) => state.sendNotif.diamondsGiveawayResult);
    const coinsGiveawayResult = useSelector((state) => state.sendNotif.coinsGiveawayResult);
    const heartsGiveawayResult = useSelector((state) => state.sendNotif.heartsGiveawayResult);
    const subGiveawayResult = useSelector((state) => state.sendNotif.subGiveawayResult);

    const [mode, setMode] = useState("diamonds");
    const [searchPrompt, setSearchPrompt] = useState(null);
    const [users, setUsers] = useState([]);

    const [normalFormData, setNormalFormData] = useState({
        userIds: [],
        count: 1
    });

    const [subFormData, setSubFormData] = useState({
        userIds: [],
        startDate: null,
        endDate: null,
    });

    const submitGiveaway = () => {
        if (isGivingaway) return;

        console.log("uhhh")

        switch (mode) {
            case "diamonds":
                if (normalFormData.userIds.length < 1) {
                    message.error("Vous n'avez pas sélectionné d'utilisateur");
                    return;
                }
                dispatch(sendDiamondsGiveaway(normalFormData));
                break;

            case "coins":
                if (normalFormData.userIds.length < 1) {
                    message.error("Vous n'avez pas sélectionné d'utilisateur");
                    return;
                }
                dispatch(sendCoinsGiveaway(normalFormData));
                break;

            case "hearts":
                if (normalFormData.userIds.length < 1) {
                    message.error("Vous n'avez pas sélectionné d'utilisateur");
                    return;
                }
                dispatch(sendHeartsGiveaway(normalFormData));
                break;

            case "sub":
                if (subFormData.userIds.length < 1) {
                    message.error("Vous n'avez pas sélectionné d'utilisateur");
                    return;
                }

                if (!subFormData.startDate || !subFormData.endDate) {
                    message.error("Vous n'avez pas sélectionné la date de début/fin");
                    return;
                }
                dispatch(sendSubGiveaway(subFormData));
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        if (mode === "diamonds" && !diamondsGiveawayResult) {
            dispatch(setDiamondGiveawayResult(null));
        }
        if (mode === "coins") {
            dispatch(setCoinsGiveawayResult(null));
        }
        if (mode === "hearts") {
            dispatch(setHeartsGiveawayResult(null));
        }
        if (mode === "sub") {
            dispatch(setSubGiveawayResult(null));
        }

        setNormalFormData({
            userIds: [],
            count: 1
        });
        setSubFormData({
            userIds: [],
            startDate: null,
            endDate: null,
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diamondsGiveawayResult, coinsGiveawayResult, heartsGiveawayResult, subGiveawayResult])

    useEffect(() => {
        if (!usersResult) return;
        const newList = [];
        for (let index = 0; index < usersResult?.data.length; index++) {
            const user = usersResult.data[index];

            newList.push({ value: user._id, label: user.email });
        }
        setUsers(newList);

    }, [usersResult]);

    useEffect(() => {
        if (!searchPrompt) {
            setUsers([]);
            return;
        }

        dispatch(fetchUsers({ email: searchPrompt }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchPrompt]);

    // Switching modes
    useEffect(() => {
        setNormalFormData({
            userIds: [],
            count: 1
        });

        setSubFormData({
            userIds: [],
            startDate: null,
            endDate: null,
        });

    }, [mode]);

    const onChangeUsers = (value) => {
        setNormalFormData({ ...normalFormData, userIds: value });
        setSubFormData({ ...subFormData, userIds: value });
    }

    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <PageHeader
                title={"Envoyer un Giveaway"}
                breadcrumbItems={["Centre des Notifications", "Giveaway"]}
            />

            <Row justify={"center"} style={{ margin: "24px", paddingRight: "30vw" }}
            >
                <Col span={24} style={{ paddingTop: 10 }} >
                    <Form layout="horizontal" style={{ marginRight: "48px", minWidth: "450px" }}>
                        <Form.Item
                            style={{ minWidth: "450px" }}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label="Type du giveaway"
                        >
                            <Select
                                onChange={(val) => setMode(val)}
                                value={mode}
                                style={{ minWidth: "450px" }}
                                options={[
                                    { value: "diamonds", label: "Diamants" },
                                    { value: "coins", label: "Pièces" },
                                    { value: "hearts", label: "Cœurs" },
                                    { value: "sub", label: "Abonnement" }
                                ]}
                            />
                        </Form.Item>
                        {mode === "sub" ? (
                            <>
                                <Form.Item
                                    style={{ minWidth: "450px" }}
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    label="Date de début"
                                >
                                    <DatePicker
                                        value={subFormData.startDate}
                                        onChange={(value) => setSubFormData({ ...subFormData, startDate: value })}
                                        style={{ minWidth: "450px", width: "100%" }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ minWidth: "450px" }}
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    label="Date de fin"
                                >
                                    <DatePicker
                                        value={subFormData.endDate}
                                        onChange={(value) => setSubFormData({ ...subFormData, endDate: value })}
                                        style={{ minWidth: "450px", width: "100%" }}
                                    />
                                </Form.Item>
                            </>
                        ) : (
                            <Form.Item
                                style={{ minWidth: "450px" }}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                label="Nombre"
                            >
                                <InputNumber
                                    min={1}
                                    value={normalFormData.count}
                                    onChange={(value) => setNormalFormData({ ...normalFormData, count: value })}
                                    style={{ minWidth: "450px", width: "100%" }}
                                />
                            </Form.Item>
                        )}
                        <Form.Item
                            style={{ minWidth: "450px", fontSize: "14px" }}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label="Recherche utilisateurs"
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                filterOption={false}
                                defaultActiveFirstOption={false}
                                notFoundContent={null}
                                value={mode === "sub" ? subFormData.userIds : normalFormData.userIds}
                                onChange={onChangeUsers}
                                onSearch={(value) => { setSearchPrompt(value) }}
                                options={users}
                                style={{ minWidth: "450px" }}
                            />
                        </Form.Item>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Space style={{ alignItems: "flex-end" }}>
                                <Button type="primary" onClick={submitGiveaway}>
                                    {isGivingaway ? <Spin className='proClientSpinner' /> : "Envoyer"}
                                </Button>
                            </Space>
                        </div>

                    </Form>
                </Col>
            </Row>

        </Content>
    )
}