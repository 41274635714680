import React, { useEffect, useState } from 'react';
import { Layout, Menu, ConfigProvider, Row, Col, Avatar, Typography, Divider, Button, Spin, Popover } from 'antd';
import {
    UserOutlined, MenuFoldOutlined,
    BarcodeOutlined, MenuUnfoldOutlined,
    FundOutlined,
    DashboardOutlined, ThunderboltOutlined, SettingOutlined, ShopOutlined, LoadingOutlined
} from '@ant-design/icons';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchThemes, fetchUserProfile, setPathname } from './dashboardSlice';
// import Home from './Home';
// import Profile from './Profile';
import frFR from 'antd/locale/fr_FR';
// import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import './dashboard.css'
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { BsPersonBoundingBox } from "react-icons/bs"
import { RiFileList3Line, RiAdminLine } from "react-icons/ri"
import { MdOutlineNotificationAdd } from "react-icons/md"
import jwt_decode from "jwt-decode";
import { setredirectToDashboard } from '../sigIn/signInSlice';
import { Helmet } from 'react-helmet';
import { fetchModules } from '../modules/moduleSlice';
// import axios from 'axios';

const { Text } = Typography;

const { Header, Content, Sider } = Layout;
const spinIcon = (
    <LoadingOutlined
        style={{
            fontSize: 24,
        }}
        spin
    />
);

const headerMenuItems = [{ key: 'analytics', title: 'Analytique Générale' },
{ key: 'pro', title: 'Contrats' }, { key: 'users', title: 'Gestion Utilisateurs' }, { key: 'content', title: 'Gestion Cactus' },
{ key: 'params', title: 'Paramètres Cactus' }, { key: 'admins', title: 'Gestion Admins' },];

let siderMenuItems = {
    analytics: [
        { key: 'stats', label: 'Statistiques Cactus', children: [{ key: 'analytics', label: 'Analytiques' }], icon: <DashboardOutlined /> },
        { key: 'finances', label: 'Finances Cactus', children: [{ key: 'finance', label: 'Analytiques' }], icon: <FundOutlined /> },
    ],
    pro: [
        { key: 'contracts', label: 'Panneau contrats', children: [{ key: 'list', label: 'Vue d’ensemble' }, { key: 'add', label: 'Ajouter un contrat' }], icon: <UserOutlined /> },
        { key: 'codes', label: 'Codes d’Accès', children: [{ key: 'history', label: 'Historique des Codes d’Accès' }], icon: <BarcodeOutlined /> },

    ],
    users: [
        { key: 'users', label: 'Panneau Clients Pro', children: [{ key: 'list', label: 'Vue d’ensemble' }], icon: <UserOutlined /> },
    ],
    content: [
        {
            key: 'modules', label: 'Modules', children: [
                { key: 'modulelist', label: "Vue d'ensemble" },
                { key: '1', label: 'Module 1' },
                { key: '2', label: 'Module 2 ' },
                { key: '3', label: 'Module 3' },
                { key: '4', label: 'Module 4' },
                { key: '5', label: 'Module 5' },
                { key: '6', label: 'Module 6' },
                { key: '7', label: 'Module 7' },
                { key: '8', label: 'Module 8' },
                // Always need to add an extra one for the if check
                // on the length of the content in the sidebar code.
                { key: '9', label: 'Module 9' },
            ],
            icon: <RiFileList3Line />
        },
        { key: 'avatar', label: 'Avatar', children: [{ key: 'items', label: 'Liste des Items' }], icon: <BsPersonBoundingBox /> },
        { key: 'defis', label: 'Défis', children: [{ key: 'list', label: 'Liste des Défis' }], icon: <ThunderboltOutlined /> },

    ],
    params: [
        { key: 'general', label: 'Paramètres Générales', children: [{ key: 'appparams', label: 'Paramètres In-App' }], icon: <SettingOutlined /> },
        { key: 'shop', label: 'Paramètres Shop', children: [{ key: 'organisation', label: 'Organisation Shop' }, { key: 'promo', label: 'Codes Promo' },], icon: <ShopOutlined /> },
        {
            key: 'notifications', label: 'Centre des Notification', children: [
                { key: 'push', label: 'Notification Push' },
                { key: 'giveaway', label: 'Giveaway' },
            ], icon: <MdOutlineNotificationAdd />
        },

    ],
    admins: [
        { key: 'panel', label: 'Panneau Administratif', children: [{ key: 'list', label: 'Vue d’ensemble' },], icon: <RiAdminLine /> },
    ],
};
const Dashboard = () => {
    // axios.interceptors.response.use(response => {
    //     console.log('AXIOS',response);
    //     return response;
    //  }, error => {
    //    if (error.response.status === 401) {
    //     //place your reentry code
    //     navigate("/signin")
    // }
    //    return error;
    //  });
    const dispatch = useDispatch();
    let location = useLocation();
    let navigate = useNavigate();

    const [collapsed, setCollapsed] = useState(false);

    const [selectedHeaderMenuItem, setSelectedHeaderMenuItem] = useState(null);
    const [selectedSiderMenuItem, setSelectedSiderMenuItem] = useState(null);
    const [helmet, setHelmet] = useState("Cactus");

    const pathname = useSelector((state) => state.dashboard.pathname);
    const fetchProfileResult = useSelector((state) => state.dashboard.fetchProfileResult);
    const isFetchingProfile = useSelector((state) => state.dashboard.isFetchingProfile);

    const fetchModulesResult = useSelector((state) => state.module.fetchModulesResult);

    const handleHeaderMenuClick = ({ key }) => {
        setSelectedHeaderMenuItem(key);
    };
    useEffect(() => {
        dispatch(setPathname(location.pathname));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    useEffect(() => {
        if (pathname !== "") {

            const pathElements = pathname.split('/').filter(Boolean);
            const key = pathElements[1]; // second element after /dashboard
            const key2 = pathElements[pathElements.length - 1];
            const key3 = pathElements[2]
            if (key && key2) {
                setSelectedHeaderMenuItem(
                    headerMenuItems.find((item) => item.key === key).key
                );
                setSelectedSiderMenuItem(pathElements[3]);
                setHelmet(
                    siderMenuItems[key].find((item) => item.key === key3).label
                );
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        dispatch(fetchUserProfile());
        dispatch(fetchModules());
        dispatch(fetchThemes());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (fetchModulesResult) {
            siderMenuItems.content[0].children = siderMenuItems.content[0].children.slice(0, fetchModulesResult.total + 1)
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchModulesResult]);
    // useEffect(() => {
    //     const unloadCallback = (event) => {
    //       event.preventDefault();
    //       event.returnValue = "";
    //       return "";
    //     };

    //     window.addEventListener("beforeunload", unloadCallback);
    //     return () => window.removeEventListener("beforeunload", unloadCallback);
    //   }, []);
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            const decodedToken = jwt_decode(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken.exp < currentTime) {
                localStorage.removeItem("token");
                navigate("/signin")

                // Token has expired
            }
        } else {
            navigate("/signin")

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    const AvatarProfile = () => {
        const content = fetchProfileResult ? (
            <div>

                <br />
                <Text>
                    <b>Email: </b> {fetchProfileResult?.admin.email}
                </Text>
                <br />

                <Text>
                    <b>Type: </b>
                    {fetchProfileResult?.admin.type}
                </Text>
                <Divider />
                <Button
                    danger
                    type="dashed"
                    block
                    // style={{ margin: 8 }}
                    onClick={() => {
                        localStorage.removeItem("token");
                        navigate("/signin")
                        dispatch(setredirectToDashboard())
                    }}
                >
                    Signout
                </Button>
            </div>
        ) : (
            <>
                <Button
                    danger
                    type="dashed"
                    block
                    // style={{ margin: 8 }}
                    onClick={() => {
                        localStorage.removeItem("token");
                        navigate("/signin")
                        dispatch(setredirectToDashboard())
                    }}
                >
                    Signout
                </Button>
                <Text>Error</Text>
            </>
        );
        return (
            <Popover content={content} trigger="click">
                <Avatar
                    shape="square"
                    size={38}
                    style={{ cursor: "pointer", backgroundColor: "#7F7F7F" }}
                >{fetchProfileResult?.admin.email[0].toUpperCase()}</Avatar>
            </Popover>
        );
    }
    const AvatarLoader = () => {
        return (
            <Spin spinning={true}>
                <Avatar

                />
            </Spin>
        );
    };
    return (
        <ConfigProvider
            locale={frFR}
            theme={{
                token: {
                    colorPrimary: "#39B13D",
                    borderRadius: "2px",
                    fontFamily: "Roboto"
                },
            }}
        >
            <Layout style={{ minHeight: '100vh' }}  >
                <Header className="header" style={{
                    height: "48px", alignItems: "center", backgroundColor: "#424242",

                    position: 'sticky',
                    top: 0,
                    zIndex: 2,
                }}>
                    <Row style={{ height: "auto", lineHeight: "48px", fontSize: '14px' }}>
                        <Col span={4}>

                        </Col>
                        <Col span={16}>
                            <Menu theme="dark" mode="horizontal"
                                selectedKeys={selectedHeaderMenuItem}
                                onClick={handleHeaderMenuClick}
                                style={{ backgroundColor: "#424242" }}>
                                {headerMenuItems.map(item =>
                                    <Menu.Item key={item.key}>
                                        {siderMenuItems[item.key][0]["children"] ?

                                            <NavLink to={`/dashboard/${item.key}/${siderMenuItems[item.key][0]["key"]}/${siderMenuItems[item.key][0]["children"][0]["key"]}`}>{item.title}</NavLink>
                                            :
                                            <NavLink to={`/dashboard/${item.key}/${siderMenuItems[item.key][0]["key"]}`}>{item.title}</NavLink>
                                        }
                                    </Menu.Item>
                                )}
                            </Menu>
                        </Col>
                        <Col span={4} >
                            <Row align="middle" justify="center">
                                <Col span={4} >
                                    <AiOutlineQuestionCircle style={{ color: "white", width: "15px", height: "15px" }} />
                                </Col>
                                <Col span={7} style={{ display: "flex" }}>

                                    {isFetchingProfile ? <AvatarLoader /> : <AvatarProfile />}

                                </Col>
                                <Col span={12} >
                                    <Text style={{ color: "white" }} >{fetchProfileResult?.admin.type}</Text>

                                </Col>
                            </Row>




                        </Col>
                    </Row>
                    <div className="logo" />

                </Header>
                <Layout>
                    <Sider trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        theme="light" width={200} className="site-layout-background" collapsible
                        collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
                        style={{ height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0, zIndex: 2 }} >
                        <div style={{ display: "flex", justifyContent: "center" }}>


                            {!collapsed ? (
                                <img
                                    src="/logo4.png"
                                    alt="logo"
                                    style={{ height: "24px", width: "117px", display: "block", margin: "12px 24px 12px 24px", }}
                                />
                            ) :
                                <img
                                    src="/C.png"
                                    alt="logo"
                                    style={{ height: "24px", width: "24px", display: "block", marginBottom: "12px", marginTop: "12px" }}
                                />
                            }
                        </div>
                        {
                            selectedHeaderMenuItem && pathname.includes(selectedHeaderMenuItem) && selectedSiderMenuItem && siderMenuItems.content[0].children.length === (fetchModulesResult?.total + 1) ?

                                <Menu
                                    mode="inline"
                                    style={{ height: '100%', borderRight: 0 }}

                                    selectedKeys={selectedSiderMenuItem}
                                    items={siderMenuItems[selectedHeaderMenuItem]}
                                    defaultOpenKeys={siderMenuItems[selectedHeaderMenuItem].map((item) => item.key)}
                                    onSelect={(item, keyPath) => {
                                        navigate(`/dashboard/${selectedHeaderMenuItem}/${item.keyPath[1]}/${item.key}`)
                                    }}
                                >
                                </Menu>
                                : <Spin spinning={true}
                                    tip="Chargement du menu"
                                    style={{ marginTop: "100px" }}
                                    indicator={spinIcon}

                                >   </Spin>
                        }
                    </Sider>
                    <Layout
                        className="site-layout"
                        style={{
                            marginLeft: !collapsed ? 200 : 80,
                        }}
                    >
                        <Content
                            className="site-layout-background"
                            style={{
                                // padding: 24,
                                margin: 0,
                                minHeight: 280,
                            }}
                        ><>
                                <Helmet>
                                    <meta charSet="utf-8" />
                                    <title>{helmet}</title>
                                </Helmet>

                                <Outlet />
                            </>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </ConfigProvider >
    );
};

export default Dashboard;
